import service from '../constant';
import axios from '../axios';

// get all product category
export const getAllProductCategory = async () => {
    return await axios.get('/product-category', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get product category by id
export const getProdCategoryById = async (id, includes = 0) => {
    return await axios.get('/product-category/' + id, {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// create product category
export const createProdCategory = async (data) => {
    return await axios.post('/product-category', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update product category
export const updateProdCategory = async (id, data) => {
    return await axios.put('/product-category/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update product category status
export const updateProdCategoryStatus = async (id, data) => {
    return await axios.put('/product-category/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete product category
export const deleteProdCategoryById = async (id) => {
    return await axios.delete('/product-category/' + id);
};
