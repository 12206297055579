import axios from 'axios';
import service from './constant';
import jwtService from '@src/auth/jwt/jwtService';
import { store } from '@store/store';
import { handleLogout } from '@store/authentication';

// const history = useHistory();

const instance = axios.create({
    baseURL: service.API_URL + '/api/v1',
});

// ** Request Interceptor
instance.interceptors.request.use(
    (config) => {
        // ** Get token from localStorage
        const accessToken = jwtService.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = JSON.parse(accessToken);
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// ** Add request/response interceptor
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response } = error;

        // ** if (status === 401) {
        if (response && response.status === 401) {
            store.dispatch(handleLogout());
            window.location.href = '/login';
        }

        // error message for bolb response
        if (response?.config?.responseType && response?.config?.responseType == 'blob') {
            const jsonData = await convertBlobToJson(response.data);
            response.data = jsonData;
        }
        return Promise.reject(error);
    }
);

const convertBlobToJson = (data) => {
    return new Promise((resolve, reject) => {
        try {
            var reader = new FileReader();
            reader.onload = function (event) {
                var blobData = event.target.result;
                var errorData = JSON.parse(blobData);
                return resolve(errorData);
            };
            reader.readAsText(data);
        } catch (err) {
            return reject(err);
        }
    });
};

export default instance;
