import service from '../constant';
import axios from '../axios';

// get all users
export const getUsers = async (includes = 0) => {
    return await axios.get('/user', {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// get user by id
export const getUserById = async (id, includes = 0) => {
    return await axios.get('/user/' + id, {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// update user status
export const updateUserStatus = async (id, data) => {
    return await axios.put('/user/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete user
export const deleteUser = async (id) => {
    return await axios.delete('/user/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create user
export const createUser = async (data) => {
    return await axios.post('/user', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update user
export const updateUser = async (id, data) => {
    return await axios.put('/user/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
