import axios from '../axios';

// get all city
export const getAllCity = async (includes = 0) => {
    return await axios.get('/city', {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// get all city by state id
export const getCityByState = async (stateId) => {
    return await axios.get(`/city?stateId=${stateId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get city by id
export const getCityById = async (id) => {
    return await axios.get('/city/' + id);
};

// create city
export const createCity = async (data) => {
    return await axios.post('/city', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update city
export const updateCity = async (id, data) => {
    return await axios.put('/city/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update city status
export const updateCityStatus = async (id, data) => {
    return await axios.put('/city/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete city
export const deleteCity = async (id) => {
    return await axios.delete('/city/' + id);
};
