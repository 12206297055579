import service from '../constant';
import axios from '../axios';

// add permission
export const addPermission = async (id, data) => {
    return await axios.post('/permission/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get permission by id
export const getPermissionsById = async (id) => {
    return await axios.get('/permission/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get permission by token
export const getPermissionsByToken = async () => {
    return await axios.get('/permission', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
