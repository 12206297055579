import service from '../constant';
import axios from '../axios';

// get all role
export const getRole = async () => {
    return await axios.get('/role');
};

// get role by id
export const getRoleById = async (id) => {
    return await axios.get('/role/' + id);
};

// create role
export const createRole = async (data) => {
    return await axios.post('/role', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update role
export const updateRole = async (id, data) => {
    return await axios.put('/role/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update role status
export const updateRoleStatus = async (id, data) => {
    return await axios.put('/role/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete role
export const deleteRole = async (id) => {
    return await axios.delete('/role/' + id);
};
