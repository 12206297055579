import service from '../constant';
import axios from '../axios';

// get all product master
export const getAllProductMaster = async () => {
    return await axios.get('/product', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get all product master
export const getAllProductExcelData = async () => {
    return await axios.get('/product/download-excel', {
        responseType: 'blob',
    });
};

// get all product master
export const bulkUpdateProductMaster = async (data) => {
    return await axios.put('/product/bulk-update', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get product master by id
export const getProductMasterById = async (id) => {
    return await axios.get('/product/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create product master
export const createProductMaster = async (data) => {
    return await axios.post('/product', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

// update product master
export const updateProductMaster = async (id, data) => {
    return await axios.put('/product/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

//update product master qty
export const updateProductMasterQty = async (id, data) => {
    return await axios.put('/product/quantity/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update product master status
export const updateProductMasterStatus = async (id, data) => {
    return await axios.put('/product/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete product master
export const deleteProductMasterById = async (id) => {
    return await axios.delete('/product/' + id);
};
