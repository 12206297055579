import axios from '../axios';

// get profile
export const getProfile = async () => {
    return await axios.get('/profile', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update profile
export const updateProfile = async (data) => {
    return await axios.put('/profile/password', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
