// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** jwtService import to get config
import jwtService from '@src/auth/jwt/jwtService';

const config = jwtService.jwtConfig;

const initialUser = () => {
    const item = window.localStorage.getItem('userData');
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser(),
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload.userData;
            state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName];

            // ** Remove user, accessToken from localStorage
            localStorage.setItem('userData', JSON.stringify(action.payload.userData));
            localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
        },
        handleLogout: (state) => {
            state.userData = {};
            state[config.storageTokenKeyName] = null;

            // ** Remove user, accessToken from localStorage
            localStorage.removeItem('userData');
            localStorage.removeItem(config.storageTokenKeyName);
        },
    },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
