import service from '../constant';
import axios from '../axios';

// get all district
export const getAllDistrict = async (includes = 0) => {
    return await axios.get('/district', {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// get all district by state id
export const getDistrictByState = async (stateId) => {
    return await axios.get(`/district?stateId=${stateId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get district by id
export const getDistrictById = async (id) => {
    return await axios.get('/district/' + id);
};

// create district
export const createDistrict = async (data) => {
    return await axios.post('/district', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update district
export const updateDistrict = async (id, data) => {
    return await axios.put('/district/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update district status
export const updateDistrictStatus = async (id, data) => {
    return await axios.put('/district/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete district
export const deleteDistrict = async (id) => {
    return await axios.delete('/district/' + id);
};
