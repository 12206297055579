import service from '../constant';
import axios from '../axios';

// get all offer
export const getAllOffer = async () => {
    return await axios.get('/offer', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get offer by id
export const getOfferById = async (id) => {
    return await axios.get('/offer/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create offer
export const createOffer = async (data) => {
    return await axios.post('/offer', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update offer
export const updateOffer = async (id, data) => {
    return await axios.put('/offer/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update offer status
export const updateOfferStatus = async (id, data) => {
    return await axios.put('/offer/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete offer
export const deleteOfferById = async (id) => {
    return await axios.delete('/offer/' + id);
};
