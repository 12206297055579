var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://orixapi.feeltechsolutions.com';
} else {
    service.API_URL = 'http://192.168.29.178:5050';
    // service.API_URL = 'https://orix-api2.feeltechsolutions.com';
}

export default service;
