import service from '../constant';
import axios from '../axios';

// get all role
export const getAllState = async () => {
    return await axios.get('/state');
};

// get role by id
export const getStateById = async (id) => {
    return await axios.get('/state/' + id);
};

// create role
export const createState = async (data) => {
    return await axios.post('/state', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update role
export const updateState = async (id, data) => {
    return await axios.put('/state/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update role status
export const updateStateStatus = async (id, data) => {
    return await axios.put('/state/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete role
export const deleteState = async (id) => {
    return await axios.delete('/state/' + id);
};
