// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// API Imports
import { getPermissionsByToken } from '../service';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getPermission = createAsyncThunk('permission/getPermission', async () => {
    return getPermissionsByToken()
        .then(async (res) => {
            let moduleGroup = res.data?.moduleGroup || [];
            let module = res.data?.module || [];
            let moduleRight = res.data?.moduleRight || [];

            return { moduleGroup: moduleGroup, module: module, moduleRight: moduleRight };
        })
        .catch((err) => {
            toast.error(err.response?.data?.message || 'Something went wrong!');
            return {};
        });
});

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        permission: {},
    },
    reducers: {
        handlePermissionChange: (state, action) => {
            state.permission = action.payload;
        },
        removePermission: (state, action) => {
            state.permission = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPermission.fulfilled, (state, action) => {
            state.permission = action.payload;
        });
    },
});

export const { handlePermissionChange, removePermission } = permissionSlice.actions;

export default permissionSlice.reducer;
