import service from '../constant';
import axios from '../axios';

// get module structure
export const getModuleStructure = async () => {
    return await axios.get('/all-module');
};

// get all module
export const getModule = async (includes = 0) => {
    return await axios.get('/module', {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// get all module with group filter
export const getModuleWithGroup = async (groupId, includes = 0) => {
    return await axios.get('/module/group/' + groupId, {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// get module by id
export const getModuleById = async (id, includes = 0) => {
    return await axios.get('/module/' + id, {
        headers: {
            'Content-Type': 'application/json',
            includes: includes,
        },
    });
};

// update module group status
export const updateModuleStatus = async (id, data) => {
    return await axios.put('/module/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete module group
export const deleteModule = async (id) => {
    return await axios.delete('/module/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create module group
export const createModule = async (data) => {
    return await axios.post('/module', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update module group
export const updateModule = async (id, data) => {
    return await axios.put('/module/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
