import service from '../constant';
import axios from '../axios';

// get all placed order item
export const getAllOrderItem = async (id) => {
    return await axios.get('/order/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get order item by id
export const getOrderItemById = async (id) => {
    return await axios.get('/order-item/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update order item status
export const updateOrderItemStatus = async (id, data) => {
    return await axios.put('/order-item/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// // create placed order item
// export const createOrderItem = async (data) => {
//     return await axios.post('/placed-order', data, {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
// };
