import service from '../constant';
import axios from '../axios';

// create placed order
export const createPlacedOrder = async (data) => {
    return await axios.post('/order', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get all placed order
export const getAllPlacedOrder = async () => {
    return await axios.get('/order', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get placed order by id
// export const getPlacedOrderById = async (id) => {
//     return await axios.get('/order/' + id, {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
// };

// update placed orderstatus
export const updatePlacedOrderStatus = async (id, data) => {
    return await axios.put('/order/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete placed order
export const deleteOrder = async (id) => {
    return await axios.delete('/order/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
