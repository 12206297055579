import service from '../constant';
import axios from '../axios';

// get all brochure
export const getAllBrochure = async () => {
    return await axios.get('/brochure', {
        headers: {
            'Content-Type': 'application/pdf',
        },
    });
};

// get brochure by id
export const getBrochureById = async (id) => {
    return await axios.get('/brochure/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create brochure
export const createBrochure = async (data) => {
    return await axios.post('/brochure', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update brochure
export const updateBrochure = async (id, data) => {
    return await axios.put('/brochure/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update brochure status
export const updateBrochureStatus = async (id, data) => {
    return await axios.put('/brochure/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete brochure
export const deleteBrochure = async (id) => {
    return await axios.delete('/brochure/' + id);
};
