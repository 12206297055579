import service from '../constant';
import axios from '../axios';

// get all discount quantity
export const getAllDiscountQuantity = async () => {
    return await axios.get('/discount-quantity', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create discount
export const createDiscount = async (data) => {
    return await axios.post('/discount', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get all discount
export const getAllDiscount = async () => {
    return await axios.get('/discount', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get discount by product
export const getDiscountByProduct = async (id) => {
    return await axios.get('/discount?productId=' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get customer discount by id
export const getDiscountById = async (id) => {
    return await axios.get('/discount/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update discount
export const updateDiscount = async (id, data) => {
    return await axios.put('/discount/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update discount status
export const updateDiscountStatus = async (id) => {
    return await axios.put('/discount/status/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete customer category
export const deleteDiscountById = async (id) => {
    return await axios.delete('/discount/' + id);
};
