import service from '../constant';
import axios from '../axios';

// get all brand name
export const getAllBrand = async () => {
    return await axios.get('/product-brand', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get module group by id
export const getBrandById = async (id) => {
    return await axios.get('/product-brand/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create module group
export const createBrand = async (data) => {
    return await axios.post('/product-brand', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update module group
export const updateBrand = async (id, data) => {
    return await axios.put('/product-brand/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update module group status
export const updateBrandStatus = async (id, data) => {
    return await axios.put('/product-brand/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete module group
export const deleteBrandById = async (id) => {
    return await axios.delete('/product-brand/' + id);
};
