import service from '../constant';
import axios from '../axios';

// get all company master
export const getAllCompanyMaster = async () => {
    return await axios.get('/company', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
export const getCustomerByStatus = async (status = 1) => {
    return await axios.get('/company?customerStatus=' + status, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get company master by id
export const getCompanyMasterById = async (id) => {
    return await axios.get('/company/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// create company master
export const createCompanyMaster = async (data) => {
    return await axios.post('/company', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update cumpany password
export const updateCompanyPassword = async (id, data) => {
    return await axios.put('/company/password/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update company master
export const updateCompanyMaster = async (id, data) => {
    return await axios.put('/company/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update company master status
export const updateCompanyMasterStatus = async (id, data) => {
    return await axios.put('/company/status/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// update customer status
export const updateCustomerStatus = async (id, data) => {
    return await axios.put('/company/profile/' + id, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// delete company master
export const deleteCompanyMasterById = async (id) => {
    return await axios.delete('/company/' + id);
};
