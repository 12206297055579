import service from '../constant';
import axios from '../axios';

// get all saved order
export const getAllSavedOrder = async () => {
    return await axios.get('/saved-order', {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// get all saved order
export const getSavedOrderById = async (id) => {
    return await axios.get('/saved-order/' + id, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
